
















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class StockDetails extends Vue {
  private reports = [
    { viewName: 'Stock/Reports/BalanceAdjustments', name: this.$t('c:stock-details:Stock Balance Adjustments') },
    { viewName: 'Stock/Reports/Deliveries', name: this.$t('c:stock-details:Stock Deliveries') },
    { viewName: 'Stock/Reports/Values', name: this.$t('c:stock-details:Stock Values') },
    { viewName: 'Stock/Reports/ValueItems', name: this.$t('c:stock-details:Stock Value Items') },
    { viewName: 'Stock/Reports/InventoriesWithDiffList', name: this.$t('c:stock-details:Stock Inventories with Difference List') },
  ]

  private navigateToReport(viewName: string) {
    this.$router.push({
      name: viewName,
    })
  }
}
